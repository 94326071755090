<template>
  <app-layout image="BG_Home.svg" :is-border-header="false" :is-carousel="true">
    <template #carousel>
      <v-carousel hide-delimiters show-arrows-on-hover style="width: 100vw" cycle :style="carouselStyle"
                  interval="9000">
        <v-carousel-item
            v-for="noticia in noticias"
            :key="noticia.id"
            :class="!$vuetify.breakpoint.xs  ? 'px-15' : ''"
            class="carousel-style__item"
        >

          <v-row class="justify-center px-10">
            <v-col cols="12" md="5" class="d-flex justify-start flex-column">
              <p class="font-weight-bold white--text text-body-1 text-sm-subtitle-2 text-md-h6 text-lg-h5 text-xl-h4">
                {{ noticia?.title?.rendered }}</p>
              <p class="white--text text-caption text-sm-body-2 text-md-subtitle-2 text-lg-subtitle-1 text-xl-h6 font-weight-light text-truncate-caroulse--style"
                 v-html="noticia?.excerpt?.rendered"></p>
              <p class="text-body-2 white--text">
                {{ `Diario El Salvador | ${moment(noticia?.date).format('DD MMMM YYYY')}` }}</p>
              <v-row class="ml-2 mb-1" dense>
                <v-btn class="" color="textCyan white--text rounded-xl" :small="$vuetify.breakpoint.smAndDown"
                       @click="showNew(noticia,noticia.id)">Ver noticia
                </v-btn>
              </v-row>
            </v-col>
            <v-spacer class="spacer-carousel" v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
            <v-col cols="12" md="5" class="d-flex justify-center px-5">

              <img class="card-shadow--style rounded-xxl" :src="noticia?.jetpack_featured_media_url"
                   :style="styleImgCarousel"/>

            </v-col>

          </v-row>
        </v-carousel-item>
      </v-carousel>

    </template>
    <template #body-content>
      <div class="px-15"
           :style="{marginTop: $vuetify.breakpoint.width < 500 ? '-90px': $vuetify.breakpoint.xs ? '20px' : $vuetify.breakpoint.sm? ' 180px'  : '40px' }"
           style="margin-bottom: 200px">
        <v-row class="justify-center align-center" style="row-gap: 50px; column-gap: 150px">
          <!--          <v-col cols="12" sm="4" lg="3" v-for="card in menuCard" :key="card.id">-->
          <v-card v-for="card in menuCard" :key="card.id" class="rounded-xxl card-shadow--style" width="400"
                  @click="redirectTo(card.redirect_to, card.id)" :style="styleCard">
            <v-card-title class="image-container pa-0 pt-2" @mouseenter="onMouseEnter(index)"
                          @mouseleave="onMouseLeave(index)">
              <img :src="require(`./assets/img/${card.img}`)" class="rounded-t-xl image" width="100%"/>
            </v-card-title>
            <v-card-text class="px-5 px-sm-10">
              <p class="textCyan--text text-body-2 text-sm-body-1 text-md-h6 text-truncate--style-title font-weight-bold ma-0 mt-5">
                {{
                  card.name
                }}</p>
              <p class="text-caption text-sm-body-1 text-truncate-card--style font-weight-bold ma-0"
                 :style="descriptionStyle">
                {{ card.desc }}</p>
            </v-card-text>
          </v-card>
          <!--          </v-col>-->


        </v-row>
      </div>
    </template>
  </app-layout>
</template>
<script>
import AppLayout from "@/components/AppLayoutComponent.vue";
import {mapState} from "vuex";
import gsap from "gsap";

export default {
  components: {
    AppLayout
  },
  data() {
    return {
      cards: [],
      noticias: [],
    }
  },
  computed: {
    ...mapState('utils', ['menuCard']),
    styleImgCarousel() {
      return {
        'max-width': '100%',
        display: 'block'
      }
    },
    styleCard() {
      return {
        flex: this.$vuetify.breakpoint.width < 700 ? '1 1 50%' : '1 1 25%',
        position: 'relative',
      }
    },
    descriptionStyle() {
      return {
        height: this.$vuetify.breakpoint.width >= 1626
            ? '80px' : this.$vuetify.breakpoint.width >= 1296
                ? '120px' : this.$vuetify.breakpoint.width >= 1000
                    ? '100px' : this.$vuetify.breakpoint.width >= 900
                        ? '130px' : this.$vuetify.breakpoint.width >= 750
                            ? '150px' : this.$vuetify.breakpoint.width >= 700
                                ? '197px' : 'auto'
      }
    },
    carouselStyle() {
      if (this.$vuetify.breakpoint.xs) {
        return {
          height: '800px !important'
        }
      } else if (this.$vuetify.breakpoint.sm) {
        return {
          height: '700px !important'
        }
      }
    }
  },
  methods: {
    redirectTo(path, id) {
      localStorage.setItem('id', id)
      this.$router.push({path: path})
    },
    async getNoticias() {
      const {data} = await this.services.Noticias.getNoticias()
      this.noticias = data
    },
    showNew(noticia, idNew) {
      this.$router.push({name: 'noticia_princial', params: {noticia: noticia, id: idNew}})
    },
    onMouseEnter(index) {
      gsap.to(this.$el.querySelectorAll(".image")[index], {scale: 0.95, duration: 0.5, ease: "power2.out"});
    },
    onMouseLeave(index) {
      gsap.to(this.$el.querySelectorAll(".image")[index], {scale: 1, duration: 0.5, ease: "power2.out"});
    },
  },
  async created() {
    await this.getNoticias()
  }
}
</script>
<style scoped>
.card-shadow--style {
  box-shadow: 0px 0px 0px -2px rgba(0, 0, 0, 0.2), 5px 5px 0px 0px rgb(0 0 0 / 8%), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}

::v-deep .v-carousel__item {
  height: 585px !important;
}

@media screen and (min-width: 775px) and (max-width: 960px) {
  .carousel-style__item >>> .v-carousel__item {
    height: 700px !important;
  }
}

.text-truncate--style {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 81px;

}

.text-truncate-card--style {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  hyphens: auto;
}


.text-truncate--style-title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 51px;
}

.text-truncate--style {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 81px;

}

.text-truncate-caroulse--style {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  word-break: break-word;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100px;
  hyphens: auto;
  white-space: break-spaces;
}

@media screen and (min-width: 960px) and (max-width: 1903px) {
  .text-truncate-caroulse--style {
    height: 113px;
  }
}

.VueCarousel-slide {
  position: relative;
  color: #fff;
  font-family: Arial;
  font-size: 24px;
  text-align: center;
  min-height: 100px;
}
</style>
